<template>
	<div class="box">
		<CustomTable
			ref="table"
			id_table="contacts_tiers"
			:busy.sync="table_busy"
			primaryKey="contact_id"
			:hide_if_empty="true"
			:items="formattedContactTiers"
			:hrefsRoutes="config_table_hrefs"
		/>
	</div>
</template>

<script type="text/javascript">
import Contact from "@/mixins/Contact.js"
import Navigation from "@/mixins/Navigation.js"

export default {
	name: "ContactTiers",
	mixins: [Contact, Navigation],
	props: {
        contact: { type: Object, default: null}
    },
	data () {
		return {
			table_busy: true,
			contact_tiers: [],
			config_table_hrefs: {
				'tiers.tiers_rs': {
					routeUniqueName: 'tiersFiche',
					routeName: 'tiersFiche',
					params: {
						tiers_id: 'tiers.tiers_id'
					}
				}
			}
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.loadTiers()
		},
		async loadTiers() {
			this.table_busy = true
			this.contact_tiers = await this.getTiersByContact(this.contact.contact_id)
			this.table_busy = false
		},
	},
	computed: {
		formattedContactTiers: function() {
			let contactsTiersFormated = []
			if(!this.contact_tiers) {
				return []
			}

			const copy_temp = this.deppCloneObj(this.contact_tiers)
			for(let i = 0; i < copy_temp.length; i++) {
				const temp = {...copy_temp[i]}
				temp.fonction = this.getArrayObjProperty(temp.fonctions, "fonction_label")
				temp.fonction = temp.fonction.map((v) => {
					return this.getTrad(v)
				})
				temp.fonction = temp.fonction.join('<br>')

				contactsTiersFormated.push(temp);
			}

			return contactsTiersFormated
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
	}
}
</script>
